import { Button } from "antd";
import { NavLink, useParams } from "react-router-dom";

export default function Qr() {
  const params = useParams();
  let uid = params?.["uid"];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "2rem",
        boxSizing: "content-box",
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          wordBreak: "break-all",
          lineHeight: "1.5rem",
        }}
      >
        <span>{uid}</span>
      </div>
      <div style={{ width: "100%", height: "2rem" }}></div>
      <div className="link">
        {/* <NavLink to={`/home/${uid}/detail`}>
          <div
            style={{
              fontWeight: "bold",
              wordBreak: "break-all",
              lineHeight: "1.5rem",
            }}
          >{`https://h5.pusenmedical.com/#/home/${uid}/detail`}</div>
        </NavLink> */}

        <Button type="primary" style={{width:'100%'}} size="large" onClick={()=>{
          window.location.href = `https://h5.pusenmedical.com/#/home/${uid}/detail`
        }}>登记/报修</Button>
      </div>
    </div>
  );
}
