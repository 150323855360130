import { createContext, FC } from 'react'
import { Form, FormProps } from 'antd-mobile'

export const formContext = createContext({} as any)

export const Item = Form.Item

interface Props extends FormProps {
  formRef?: any
}

const FormComponent: FC<Props> = (props) => {
  const { children, formRef, ...rest } = props
  const form = Form.useForm()
  if (formRef) {
    formRef.current = form?.[0]
  }

  return (
    <formContext.Provider value={form?.[0]}>
      <Form form={form?.[0]} {...rest}>{children}</Form>
    </formContext.Provider>
  )
}

export default FormComponent