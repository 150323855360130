import { useContext, useEffect } from "react"
import { Input } from "antd-mobile";

import { formContext, Item } from '@/components/Form'
import useLocale from "@/hooks/useLocale";

import styles from "./index.module.scss";

const FormTel = (props) => {
  const { isChina } = props
  const { getLocaleText } = useLocale();
  const form = useContext(formContext)

  useEffect(() => {
    form.setFieldValue("regCode", isChina ? "86" : "")
  }, [isChina])
  
  return (
    <div className={styles["container"]}>
      {
        !isChina && (
          <Item
            name="regCode"
            label={getLocaleText("input_label_phone_area")}
            rules={[{ required: true, message: getLocaleText("input_msg_phone_area") }]}
          >
            <Input placeholder={getLocaleText("input_placeholder_phone_area")} />
          </Item>
        )
      }
      
      <Item
        className={isChina ? "" : styles["tel-item"]}
        name="linkTel"
        label={getLocaleText("input_label_phone")}
        rules={[{ required: true, message: getLocaleText("input_msg_phone") }]}
      >
        <Input placeholder={getLocaleText("input_placeholder_phone")} />
      </Item>
    </div>
  );
};

export default FormTel;
