import React from 'react'
import { ConfigProvider } from 'antd-mobile'

import zhCN from 'antd-mobile/es/locales/zh-CN'
import zhTW from 'antd-mobile/es/locales/zh-TW'
import enUS from 'antd-mobile/es/locales/en-US'

import useLocale from '@/hooks/useLocale';

interface Props {
  children: React.ReactNode | React.ReactElement;
}

const Layout: React.FC<Props> = (props) => {
  const { language, getLocaleText } = useLocale()

  const localeMap = {
    'zh-CN': zhCN,
    'zh-TW': zhTW,
    en: enUS
  }

  // if (!isInitialization && process.env.NODE_ENV === 'production')
  //   return <>页面初始化...</>;

  return <ConfigProvider locale={localeMap[language]}>{props.children}</ConfigProvider>;
};

export default Layout;
