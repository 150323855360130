import { useMemo, useCallback, useEffect } from 'react'
import { useRequest as useRequestAlova } from 'alova'

import useLanguage from './useLanguage'

const useRequest = (params: any = {}, fn, config?) => {
  const { enabled = true, ...restConfig } = config || {}
  const [language] = useLanguage()

  const fnParams = useMemo(() => {
    const result: any[] = []

    result[0] = language
    if (typeof params === 'object') {
      for (const key in params) {
        result.push(params[key])
      }
    }else {
      result.push(params)
    }

    return result
  }, [language, JSON.stringify(params || {})])

  const alovaFn = useCallback(
    fn,
    fnParams
  )

  const result = useRequestAlova((params) => alovaFn(params), {
    immediate: false,
    ...(restConfig || {})
  })

  useEffect(() => {
    enabled && result?.send?.(params)
  }, [fnParams, enabled])

  return result as any
}

export default useRequest