import { useState, useContext, useEffect, useMemo } from "react";
import { Picker, SearchBar } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";

import { formContext } from "@/components/Form";

import "./index.scss";

const Select = (props) => {
  const { name, onConfirm, placeholder = "请选择", columns, disabled } = props;
  const form = useContext(formContext);
  const [value, setValue] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [kw, setKw] = useState("");

  const text = columns?.find((item) => item.value === value?.[0])?.label;

  const renderColumns = useMemo(() => {
    if (!kw) return columns
    return columns?.filter(item => {
      const searchText = kw.toLowerCase()
      // const value = item?.value?.toLowerCase()
      const label = item?.label?.toLowerCase()
      return label?.includes?.(searchText)
    })
  }, [kw, columns])

  const handleValueChange = (v) => {
    setValue(v);
    form?.setFieldValue(name, v?.[0]);
    onConfirm?.(v?.[0], form);
  };

  useEffect(() => {
    setValue([String(form.getFieldValue(name))]);
  }, [form.getFieldValue(name)]);

  return (
    <>
      <div className={`component-select-btn ${disabled ? "component-select-btn-disabled" : ""}`} onClick={() => !disabled && setVisible(true)}>
        {text ? (
          <span>{text}</span>
        ) : (
          <span className="component-select-placeholder">{placeholder}</span>
        )}
        { !disabled && <RightOutline /> }
      </div>
      <Picker
        title={<SearchBar onChange={(kw) => setKw(kw)} onSearch={(kw) => setKw(kw)} />}
        columns={[renderColumns || []]}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        value={value}
        onConfirm={handleValueChange}
      />
    </>
  );
};

export default Select;
