import { createAlova } from 'alova'
import GlobalFetch from 'alova/dist/adapter/globalfetch.esm'
import ReactHook from 'alova/dist/hooks/reacthook.esm'
import { Toast } from 'antd-mobile'

import { LANGUAGE_KEY } from '@/constants'

const alovaInstance = createAlova({
  // baseURL: '/api',
  statesHook: ReactHook,
  requestAdapter: GlobalFetch(),
  beforeRequest(method) {
    // const openId = getUserInfoCache()?.openId
    // if (openId) {
    //   method.config.headers['x-user-id'] = openId
    // }

    // 开发使用
    // if (process.env.NODE_ENV === 'development') {
    //   // 测试 openId：8264989d8f8743fc838867f51d9c82b2
    //   // const openId = 'dbba3be0b1354ef091379bd79d79b51c'
    //   const openId = '8559e210a85d433e96445a3d427c9b14' // 芹菜
    const language = localStorage.getItem(LANGUAGE_KEY)
    language && (method.config.headers['Accept-Language'] = language)
    // }
  },
  responded: {
    onSuccess: async (response: any, method) => {
      if (response.status >= 400) {
        console.error(response.statusText)
      }
      const json = await response.json()

      return json

      if (json.code != 200) {
        Toast.show({
          content: json.msg,
        })
        return
      }

      return json?.body || json?.data || json
    },
    onError: (err) => {
      Toast.show({
        content: err,
      })
    },
  },
  // 请求超时时间，单位为毫秒，默认为0，表示永不超时
  timeout: 50000,
  // 只保留10秒
  localCache: { GET: 5 * 1000 },
})

const baseConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
}

export const get = (
  url: string,
  params: { [key: string]: any } = {},
  config?: { [key: string]: any }
) => {
  let str = ''
  for (const key in params) {
    str += `${key}=${params[key]}&`
  }
  str = str.slice(0, -1)

  return alovaInstance.Get(`${url}?${str}`, {
    ...baseConfig,
    ...(config ?? {}),
  })
}

export const post = (
  url: string,
  params = {},
  config?: { [key: string]: any }
) => {
  return alovaInstance.Post(url, params, {
    ...baseConfig,
    ...(config ?? {}),
  })
}

export const put = (
  url: string,
  params = {},
  config?: { [key: string]: any }
) => {
  return alovaInstance.Put(url, params, {
    ...baseConfig,
    ...(config ?? {}),
  })
}

export const del = (
  url: string,
  params = {},
  config?: { [key: string]: any }
) => {
  return alovaInstance.Delete(url, params, {
    ...baseConfig,
    ...(config ?? {}),
  })
}

export default alovaInstance
