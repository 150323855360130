export default {
  china: "China",
  phone: "Hotline",
  email: "Customer Service Email",

  home_title: "Customer Support Form",
  home_no_register_notice_1: "The product has not been registered,",
  home_no_register_notice_2: "please register and report for repair.",
  home_register_notice_1: 'Proceed by clicking “Continue”',
  home_register_notice_2: "below",
  home_register_name_label: "Device name",
  home_register_model_label: "Product model",
  home_register_date_label: "Date of production",
  home_register_state_label: "State",
  home_register_state_value_normal: "normal",
  home_register_state_value_repairs: "in progress",
  home_register_state_value_finished: "finished",
  home_register_country_label: "Country",
  home_register_region_label: "Hospital Address",
  home_register_hospital_label: "Hospital Name",
  home_register_depart_label: "Department",

  register_title: "Register",
  register_success_title: "Register Success",

  report_title: "Incident Report",
  report_success_title: "Report Success",
  report_record_title: "Report Record",
  report_record_date: "Report Time",
  report_record_state: "Report State",
  report_record_fault: "Fault Feedback",
  report_record_code: "Report Code",
  report_record_begin_time: "Begin Time",
  report_record_end_time: "End Time",
  report_record_details_title: "Report Details",

  report_state_map_pending: "pending",
  report_state_map_being: "being processed",
  report_state_map_done: "Done",

  btn_register: "Register",
  btn_update: "Modify information",
  btn_repairs: "Continue",
  btn_repairs_record: "Track your submission",
  btn_submit: "Submit",
  btn_back_home: "Back Home",
  btn_modify_record: "Modify",

  input_label_country: "Country",
  input_msg_country: "The country cannot be empty",
  input_placeholder_country: "Please select a country",
  input_label_region: "Hospital Address",
  input_msg_region: "The hospital address cannot be empty",
  input_placeholder_region: "Please select a hospital address",
  input_placeholder_region_other: "Please input a hospital address",
  input_label_hospital: "Hospital Name",
  input_msg_hospital: "The hospital name cannot be empty",
  input_placeholder_hospital: "Please input a hospital name",
  input_label_depart: "Department",
  input_msg_depart: "The department cannot be empty",
  input_placeholder_depart: "Please select a department",
  input_label_depart_other: "Department Other",
  input_msg_depart_other: "The department other cannot be empty",
  input_placeholder_depart_other: "Please input a department",
  input_label_linker: "Contact Person",
  input_msg_linker: "The contact person cannot be empty",
  input_placeholder_linker: "Please input a contact person",
  input_label_phone: "Cell Phone",
  input_msg_phone: "The cell phone cannot be empty",
  input_placeholder_phone: "Please input a cell phone number",
  input_label_phone_area: "Country Code",
  input_msg_phone_area: "The country code cannot be empty",
  input_placeholder_phone_area: "Please input a country code",
  input_label_email: "Email",
  input_placeholder_email: "Please input a email",
  input_msg_email: "The email cannot be empty",
  input_label_account: "Social Media Account",
  input_placeholder_account: "Please input a social media account",
  input_label_fault: "Description of Incident",
  input_msg_fault: "The fault feedback cannot be empty",
  input_placeholder_fault: "Please select a fault",
  input_label_fault_other: "Other Fault",
  input_placeholder_fault_other: "Please input other fault",
  input_label_reported: "Whether to report adverse events",
  input_label_reported_yes: "yes",
  input_label_reported_no: "no",
  input_label_disinfectWay: "Disinfection Method",
  input_label_disinfectWay_other: "Disinfection Method Other",
  report_record_fault_file: "Fault photo or video",
  input_msg_disinfectWay: "The Disinfection Method cannot be empty",
  input_placeholder_disinfectWay: "Please select a disinfection method",
  input_placeholder_disinfectWay_other: "Please input the other disinfection method",
  input_label_img: "Upload related pictures or videos",

  submit_success_text: "Submission Success",
};
