export default {
  china: "中国",
  phone: "售后电话",
  email: "售后邮箱",

  home_title: "设备信息",
  home_no_register_notice_1: "当前产品未登记",
  home_no_register_notice_2: "请登记后报修",
  home_register_notice_1: "点击下方「售后」按钮",
  home_register_notice_2: "可对耗材进行售后",
  home_register_name_label: "器械名称",
  home_register_model_label: "产品型号",
  home_register_date_label: "出厂日期",
  home_register_state_label: "报修状态",
  home_register_state_value_normal: "正常",
  home_register_state_value_repairs: "报修中",
  home_register_state_value_finished: "已完成",
  home_register_country_label: "国家",
  home_register_region_label: "区域",
  home_register_hospital_label: "医院",
  home_register_depart_label: "科室",

  register_title: "登记",
  register_success_title: "登记成功",

  report_title: "报修",
  report_success_title: "报修成功",
  report_record_title: "报修记录",
  report_record_date: "报修时间",
  report_record_state: "报修状态",
  report_record_fault: "故障反馈",
  report_record_code: "报修编号",
  report_record_begin_time: "开始处理时间",
  report_record_end_time: "结束处理时间",
  report_record_details_title: "报修记录详情",

  report_state_map_pending: "待处理",
  report_state_map_being: "处理中",
  report_state_map_done: "已完成",

  btn_register: "去登记",
  btn_update: "修改登记",
  btn_repairs: "售后",
  btn_repairs_record: "售后记录",
  btn_submit: "提交",
  btn_back_home: "返回首页",
  btn_modify_record: "修改报修单",

  input_label_country: "国家",
  input_msg_country: "国家不能为空",
  input_placeholder_country: "请选择国家",
  input_label_region: "区域",
  input_msg_region: "区域不能为空",
  input_placeholder_region: "请选择区域",
  input_placeholder_region_other: "请输入区域",
  input_label_hospital: "医院",
  input_msg_hospital: "医院不能为空",
  input_placeholder_hospital: "请输入医院",
  input_label_depart: "科室",
  input_msg_depart: "科室不能为空",
  input_placeholder_depart: "请选择科室",
  input_label_depart_other: "其他科室",
  input_msg_depart_other: "其他科室不能为空",
  input_placeholder_depart_other: "请输入其他科室",
  input_label_linker: "医院联系人",
  input_msg_linker: "医院联系人不能为空",
  input_placeholder_linker: "请输入医院联系人",
  input_label_phone: "联系电话",
  input_msg_phone: "联系电话不能为空",
  input_placeholder_phone: "请输入联系电话",
  input_label_phone_area: "国家区号",
  input_msg_phone_area: "国家区号不能为空",
  input_placeholder_phone_area: "请输入国家区号",
  input_label_email: "邮箱",
  input_placeholder_email: "请输入邮箱",
  input_label_account: "社交账号",
  input_placeholder_account: "请输入社交账号",
  input_label_fault: "故障反馈",
  input_msg_fault: "故障反馈不能为空",
  input_placeholder_fault: "请选择故障",
  input_label_fault_other: "其他故障",
  input_placeholder_fault_other: "请输入其他故障",
  input_label_reported: "是否上报不良事件",
  input_label_reported_yes: "是",
  input_label_reported_no: "否",
  input_label_disinfectWay: "消毒方式",
  input_label_disinfectWay_other: "其他消毒方式",
  report_record_fault_file: "故障照片或视频",
  input_msg_disinfectWay: "消毒方式不能为空",
  input_placeholder_disinfectWay: "请选择消毒方式",
  input_placeholder_disinfectWay_other: "请输入其他消毒方式",
  input_label_img: "上传故障照片或视频",

  submit_success_text: "提交成功",
};
