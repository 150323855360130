import { Button } from 'antd-mobile'
import { useParams, useNavigate } from 'react-router'

import useLocale from '@/hooks/useLocale'
import Container from '@/components/Container'
import SuccessIcon from '@/assets/imgs/register_success.png'

import styles from './index.module.scss'

const Success = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { getLocaleText } = useLocale()

  return (
    <Container title={getLocaleText('register_success_title')} className={styles.container}>
      <img src={SuccessIcon} />
      <span>{getLocaleText('submit_success_text')}</span>

      <Button
        color='primary'
        fill='outline'
        onClick={() => {
          navigate(`/report/${params.machineUdi}`)
        }}
      >
        {getLocaleText('btn_repairs')}
      </Button>
      <span
        className={styles.back}
        onClick={() => {
          const uid = localStorage.getItem('uid')
          navigate(`/home/${uid}`)
        }}
      >
        {getLocaleText('btn_back_home')}
      </span>
    </Container>
  )
}

export default Success