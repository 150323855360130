import { useState, useRef, useContext, useEffect, useMemo } from 'react'
import { Picker, DotLoading } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'

import useRegion from '@/hooks/useRegion'
import { formContext, Item } from '@/components/Form'

import './index.scss'

const Select = (props) => {
  const {
    name,
    disabled = false,
    onConfirm,
    placeholder = '请选择'
  } = props
  const form = useContext(formContext)
  const [columns, setColumns] = useState<any[]>([])
  const formValue = form.getFieldValue(name)
  const [value, setValue] = useState<any>([])
  const [visible, setVisible] = useState(false)
  const { data, loading } = useRegion()
  const loadingRef = useRef(loading)
  loadingRef.current = loading

  const handleChange = (value) => {
    const result: any[] = []

    result[0] = data?.map(item => ({
      value: item.value,
      label: item.text,
      children: item.children
    })) || []

    result[1] = result[0]?.find(item => item.value === value?.[0])?.children?.map(item => ({
      value: item.value,
      label: item.text,
      children: item.children
    })) || []

    result[2] = result[1]?.find(item => item.value === value?.[1])?.children?.map(item => ({
      value: item.value,
      label: item.text
    })) || []

    setColumns(result)
  }

  const text = useMemo(() => {
    const result: string[] = []

    columns?.forEach((list, index) => {
      list?.forEach(item => {
        if (item.value === value[index]) {
          result.push(item.label)
        }
      })
    })

    return result
  }, [value, columns])

  const handleValueChange = (v) => {
    setValue(v)
    form?.setFieldValue(name, v)
    onConfirm?.(v, form)
  }

  const handleShow = () => {
    if (disabled) return

    const callback = () => {
      setTimeout(() => {
        if (!loadingRef.current) {
          setVisible(true)
        }else {
          callback()
        }
      }, 100)
    }
    callback()
  }

  useEffect(() => {
    if (!data || !formValue || (JSON.stringify(value) === JSON.stringify(formValue))) return
    setValue(formValue)
    handleChange(formValue)
  }, [formValue, data])

  useEffect(() => {
    handleChange([])
  }, [])

  return (
    <>
      <Item style={{display: 'none'}} name='provinceCode'/>
      <Item style={{display: 'none'}} name='cityCode'/>
      <Item style={{display: 'none'}} name='regionCode'/>
      <div className={`component-select-btn ${disabled ? 'component-select-btn-disabled' : ''}`} onClick={handleShow}>
        {
          loading ? (
            <DotLoading />
          ) : (
            text?.length > 0 ? (
              <span>{text?.join('，')}</span>
            ) : (
              <span className='component-select-placeholder'>{placeholder}</span>
            )
          )
        }
        {
          !disabled && <RightOutline />
        }
      </div>
      <Picker
        columns={columns}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
        value={value}
        onSelect={handleChange}
        onConfirm={handleValueChange}
      />
    </>
  )
}

export default Select