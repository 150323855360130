import { Button } from 'antd-mobile'
import { useParams, useNavigate } from 'react-router'

import useLocale from '@/hooks/useLocale'
import Container from '@/components/Container'
import Tel from '@/components/Tel'
import SuccessIcon from '@/assets/imgs/register_success.png'

import styles from './index.module.scss'

const Success = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { getLocaleText } = useLocale()

  return (
    <Container title={getLocaleText('report_success_title')} className={styles.container}>
      <img src={SuccessIcon} />
      <span>{getLocaleText('submit_success_text')}</span>

      <Button
        color='primary'
        fill='outline'
        onClick={() => {
          const uid = localStorage.getItem('uid')
          navigate(`/home/${uid}`)
        }}
      >
        {getLocaleText('btn_back_home')}
      </Button>
      <span
        className={styles.back}
        onClick={() => {
          navigate(`/report/record/${params.machineUdi}`)
        }}
      >
        {getLocaleText('btn_repairs_record')}
      </span>

      <div className={styles.card}>
        <div className={styles.row}>
          <span>{getLocaleText('phone')}：</span>
          <Tel />
        </div>
        <div className={styles.row}>
          <span>{getLocaleText('email')}：</span>
          <span>cs@pusenmedical.com</span>
        </div>
      </div>
    </Container>
  )
}

export default Success