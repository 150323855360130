import { List } from 'antd-mobile'
import { useParams, useNavigate } from 'react-router'

import Container from '@/components/Container'
import useRequest from '@/hooks/useRequest'
import useDic from '@/hooks/useDic'
import useLocale from '@/hooks/useLocale'
import { device } from '@/service'
import { DIC_FAULT } from '@/constants'

import styles from './index.module.scss'

export const getStatus = (status, getLocaleText) => {
  const map = {
    0: {
      text: getLocaleText?.('report_state_map_pending'),
      color: '#FF0000'
    },
    1: {
      text: getLocaleText?.('report_state_map_being'),
      color: '#1D65E3'
    },
    9: {
      text: getLocaleText?.('report_state_map_done'),
      color: '#00933B'
    },
  }

  return map[status]
}

const Record = () => {
  const params = useParams()
  const { getLocaleText } = useLocale()
  const naviagte = useNavigate()
  const faultColumns = useDic(DIC_FAULT)
  const { data } = useRequest(params.machineUdi, device.recordPage, {force: true})

  return (
    <Container title={getLocaleText('report_record_title')} className={styles.container}>
      <List>
      {
        data?.map(item => {
          const status = getStatus(item.state, getLocaleText)
          const fault = faultColumns?.find(faultItem => faultItem.value == item.fault)

          return (
            <div key={item.id} className={styles.card} onClick={() => naviagte(`/report/details/${item.id}?machineUdi=${params.machineUdi}`)}>
              <div className={styles.row}>
                <span>{getLocaleText('report_record_date')}：</span>
                <span>{item.modifyTime}</span>
              </div>
              <div className={styles.row}>
                <span>{getLocaleText('report_record_state')}：</span>
                <span style={{color: status.color}}>{status.text}</span>
              </div>
              <div className={styles.row}>
                <span>{getLocaleText('report_record_fault')}：</span>
                <span>{item.fault == '999' ? item.faultOther : fault?.label}</span>
              </div>
            </div>
          )
        })
      }
      </List>
    </Container>
  )
}

export default Record