export default {
  china: "中國",
  phone: "售後電話",
  email: "售後郵箱",

  home_title: "設備信息",
  home_no_register_notice_1: "當前產品未登記",
  home_no_register_notice_2: "請登記後報修",
  home_register_notice_1: "點擊下方「售後」按鈕",
  home_register_notice_2: "可對耗材進行售後",
  home_register_name_label: "器械名稱",
  home_register_model_label: "產品型號",
  home_register_date_label: "出廠日期",
  home_register_state_label: "報修狀態",
  home_register_state_value_normal: "正常",
  home_register_state_value_repairs: "報修中",
  home_register_state_value_finished: "已完成",
  home_register_country_label: "國家",
  home_register_region_label: "區域",
  home_register_hospital_label: "醫院",
  home_register_depart_label: "科室",

  register_title: "登記",
  register_success_title: "登记成功",

  report_title: "報修",
  report_success_title: "報修成功",
  report_record_title: "報修記錄",
  report_record_date: "報修時間",
  report_record_state: "報修狀態",
  report_record_fault: "故障反饋",
  report_record_code: "報修編號",
  report_record_begin_time: "開始處理時間",
  report_record_end_time: "結束處理時間",
  report_record_details_title: "報修記錄詳情",

  report_state_map_pending: "待處理",
  report_state_map_being: "處理中",
  report_state_map_done: "已完成",

  btn_register: "去登記",
  btn_update: "修改登記",
  btn_repairs: "售後",
  btn_repairs_record: "售後記錄",
  btn_submit: "提交",
  btn_back_home: "返回首頁",
  btn_modify_record: "修改報修單",

  input_label_country: "國家",
  input_msg_country: "國家不能為空",
  input_placeholder_country: "請選擇國家",
  input_label_region: "區域",
  input_msg_region: "區域不能為空",
  input_placeholder_region: "請選擇區域",
  input_placeholder_region_other: "請輸入區域",
  input_label_hospital: "醫院",
  input_msg_hospital: "醫院不能為空",
  input_placeholder_hospital: "請輸入醫院",
  input_label_depart: "科室",
  input_msg_depart: "科室不能為空",
  input_placeholder_depart: "請輸入科室",
  input_label_depart_other: "其他科室",
  input_msg_depart_other: "其他科室不能為空",
  input_placeholder_depart_other: "請輸入其他科室",
  input_label_linker: "醫院聯繫人",
  input_msg_linker: "醫院聯繫人不能為空",
  input_placeholder_linker: "請輸入醫院聯繫人",
  input_label_phone: "聯繫電話",
  input_msg_phone: "聯繫電話不能為空",
  input_placeholder_phone: "請輸入聯繫電話",
  input_label_phone_area: "國家區號",
  input_msg_phone_area: "國家區號不能為空",
  input_placeholder_phone_area: "請輸入國家區號",
  input_label_email: "郵箱",
  input_placeholder_email: "請輸入郵箱",
  input_label_account: "社交賬號",
  input_placeholder_account: "請輸入社交賬號",
  input_label_fault: "故障反餽",
  input_msg_fault: "故障反餽不能為空",
  input_placeholder_fault: "請選擇故障反饋",
  input_label_fault_other: "其他故障",
  input_placeholder_fault_other: "請輸入其他故障",
  input_label_reported: "是否上報不良事件",
  input_label_reported_yes: "是",
  input_label_reported_no: "否",
  input_label_disinfectWay: "消毒方式",
  input_label_disinfectWay_other: "其他消毒方式",
  report_record_fault_file: "故障照片或視頻",
  input_msg_disinfectWay: "消毒方式不能為空",
  input_placeholder_disinfectWay: "請選擇消毒方式",
  input_placeholder_disinfectWay_other: "請輸入其他消毒方式",
  input_label_img: "上傳故障照片或視頻",

  submit_success_text: "提交成功",
};
