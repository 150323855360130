import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import VConsole from "vconsole";

import Layout from "@/layout";
import routes from "@/config/routes";

import "./index.css";

// 正式环境不展示控制台
if (
  !window.location.host.includes("ilonggang.lggov.cn") &&
  process.env.NODE_ENV === "development"
) {
  new VConsole({ theme: "dark" });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RecoilRoot>
    <Layout>
      <HashRouter>
        <Routes>
          {routes?.map((route: any) => (
            <Route
              key={route.path}
              path={route.path}
              Component={route.component}
            />
          ))}
        </Routes>
      </HashRouter>
    </Layout>
  </RecoilRoot>
);
