import { useMemo } from "react"

import useDic from "@/hooks/useDic";
import { DIC_COUNTRY } from "@/constants";

const useCountry = () => {
  const columns = useDic(DIC_COUNTRY);
  
  const result = useMemo(() => {
    const result = columns?.filter(item => item.value !== "China")
    result?.unshift?.({
      label: "中国",
      value: "China"
    })
    return result
  }, [columns])

  return result
}

export default useCountry