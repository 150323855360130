import FlagChina from "@/assets/imgs/flag_china.webp"
import FlagUSA from "@/assets/imgs/flag_usa.webp"

import routes from './routes'

export const languageKey =  {
  simple: 'zh-CN',
  complex: 'zh-TW',
  english: 'en'
}

export const languageColumns = [[
  {label: '简体中文', value: languageKey.simple, img: FlagChina},
  {label: '繁體中文', value: languageKey.complex, img: FlagChina},
  {label: 'English', value: languageKey.english, img: FlagUSA},
]]

export default {
  history: { type: 'hash' },
  publicPath: './',
  // hash: true,
  routes,
  npmClient: 'pnpm',
}
