import { Button } from "antd-mobile";
import { useNavigate } from "react-router";

import ConsumableImg from "@/assets/imgs/consumable_img.png";
import Logo from "@/assets/imgs/logo.jpg";
import NoRegisterImg from "@/assets/imgs/no_register.png";
import Container from "@/components/Container";
import LanguagePicker from "@/components/LanguagePicker";
import Tel from "@/components/Tel";
import { DIC_COUNTRY } from "@/constants";
import useDic from "@/hooks/useDic";
import useLocale from "@/hooks/useLocale";
import useRegion from "@/hooks/useRegion";
import useScanData from "@/hooks/useScanData";

import dayjs from "dayjs";
import styles from "./index.module.scss";

const NoRegister = (props) => {
  const { id, udi } = props;
  const navigate = useNavigate();
  const { getLocaleText } = useLocale();

  return (
    <div className={styles["no-register"]}>
      <img src={NoRegisterImg} />
      <span>{getLocaleText("home_no_register_notice_1")}</span>
      <span>{getLocaleText("home_no_register_notice_2")}</span>
      <Button
        color="primary"
        fill="solid"
        onClick={() => {
          navigate(`/register/${udi}`);
        }}
      >
        {getLocaleText("btn_register")}
      </Button>
    </div>
  );
};

const Register = (props) => {
  const {
    id,
    udi,
    type,
    name = "",
    model = "",
    bornDate = "",
    country = "",
    provinceName = "",
    cityName = "",
    regionName = "",
    otherRegion = "",
    state,
    hospital = "",
    depart = "",
    departOther
  } = props;
  const navigate = useNavigate();

  const { getLocaleText, getLocaleKey } = useLocale();

  const states = {
    0: "home_register_state_value_normal",
    1: "home_register_state_value_repairs",
    9: "home_register_state_value_finished",
  };

  return (
    <div className={styles.register}>
      {type == 2 ? (
        <div className={styles["register-notice"]}>
          <img src={ConsumableImg} />
          <span>{getLocaleText("home_register_notice_1")}</span>
          <span>{getLocaleText("home_register_notice_2")}</span>
        </div>
      ) : (
        <>
          <div className={styles.card}>
            {/* <div className={styles.row}>
                <span>{getLocaleText('home_register_name_label')}：</span>
                <span>{props[getLocaleKey('name')]}</span>
              </div> */}
            <div className={styles.row}>
              <span>{getLocaleText("home_register_model_label")}：</span>
              <span>{model}</span>
            </div>
            <div className={styles.row}>
              <span>{getLocaleText("home_register_date_label")}：</span>
              <span>
                {bornDate ? dayjs(bornDate).format("YYYY-MM-DD") : ""}
              </span>
            </div>
            <div className={styles.row}>
              <span>{getLocaleText("home_register_state_label")}：</span>
              <span>{getLocaleText(states[state])}</span>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.row2}>
              <span>{getLocaleText("home_register_country_label")}：</span>
              <span>
                {country === "China" ? getLocaleText("china") : country}
              </span>
            </div>
            <div className={styles.row2}>
              <span>{getLocaleText("home_register_region_label")}：</span>
              <span>
                {
                  country === "China" ? (
                    `${provinceName} ${cityName} ${regionName}`
                  ) : (
                    otherRegion
                  )
                }
              </span>
            </div>
            <div className={styles.row2}>
              <span>{getLocaleText("home_register_hospital_label")}：</span>
              <span>{hospital}</span>
            </div>
            <div className={styles.row2}>
              <span>{getLocaleText("home_register_depart_label")}：</span>
              <span>{["其他", "Other"].includes(depart) ? departOther : depart}</span>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.row}>
              <span>{getLocaleText("phone")}：</span>
              <Tel />
            </div>
            <div className={styles.row}>
              <span>{getLocaleText("email")}：</span>
              <span>cs@pusenmedical.com</span>
            </div>
          </div>
        </>
      )}

      <div className={styles["btn-group"]}>
        <div className={styles["top-btn"]}>
          {type !== 2 ? (
            <Button
              color="primary"
              fill="solid"
              onClick={() => navigate(`/register/${udi}`)}
            >
              {getLocaleText("btn_update")}
            </Button>
          ) : (
            <></>
          )}
          <Button
            color="primary"
            fill="solid"
            onClick={() => navigate(`/report/${udi}`)}
          >
            {getLocaleText("btn_repairs")}
          </Button>
        </div>
        <span onClick={() => navigate(`/report/record/${udi}`)}>
          {getLocaleText("btn_repairs_record")}
        </span>
      </div>
    </div>
  );
};

const Home = () => {
  const { getLocaleText } = useLocale();
  const data = useScanData();

  return (
    <Container
      title={getLocaleText("home_title")}
      isBackHidden={true}
      containerClassName={styles.container}
    >
      <div className={styles.header}>
        <img src={Logo} />
        <LanguagePicker />
      </div>
      {/*type 1 设备，2 耗材 耗材可以直接报修 */}
      {data?.actived == 1 || data?.type == 2 ? (
        <Register {...(data || {})} />
      ) : (
        <NoRegister id={data?.id} udi={data?.udi} />
      )}
    </Container>
  );
};

export default Home;
