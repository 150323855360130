import { useState, useEffect } from "react"
import { useRequest } from 'alova'

import { getPosition } from "@/service"
import useLanguage from "@/hooks/useLanguage"

const usePosition = () => {
  const { data, send } = useRequest<any, any, any, any, any, any, any>((params) => getPosition(params), {
    immediate: false,
  })
  const [language] = useLanguage()
  const isChina = data ? data?.country == 'CN' : (language?.[0] === 'zh-CN')
  
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((data) => {
      send({
        lat: data.coords.latitude,
        long: data.coords.longitude
      })
    })
  }, [])

  return {
    data,
    isChina
  }
}

export default usePosition