import React from 'react'
import { useNavigate } from 'react-router'

import Back from '@/assets/imgs/enter.png'

import './index.scss'

export interface Props {
  onBack?: () => void
  title: string
  isBackHidden?: boolean
  isHomeBack?: boolean
  rightSlot?: React.ReactNode | React.ReactElement
}

const Header: React.FC<Props> = (props) => {
  const navigate = useNavigate()

  const {
    title = '',
    isBackHidden = false,
    rightSlot,
    isHomeBack,
    onBack
  } = props

  const handleBack = () => {
    if (onBack) {
      onBack?.()
    }else {
      isHomeBack ? sc.goback() : navigate(-1)
    }
  }

  return (
    <div className='component-header-container'>
      <div className='component-header-back-box'>
        {
          !isBackHidden && <img src={Back} alt="" onClick={handleBack}/>
        }
      </div>
      <span className='component-header-title'>{title}</span>
      <div className='component-header-right-box'>{rightSlot}</div>
    </div>
  )
}

export default Header