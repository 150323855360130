import { get, post, put } from "@/utils/http";
import { get as getBase } from "@/utils/httpBase";

export const device = {
  scan: (id) => get(`/machine/scan/${id}`),
  register: (params) => post("/machine/register", params),
  modify: (params) => put(`/machine/${params.id}`, params),
  report: (params) => post("/repairment/report-fault", params),
  recordPage: (machineUdi) => get(`/repairment/of/${machineUdi}`, { size: 2 }),
  recordOne: (id) => get(`/repairment/${id}`),
  recordModify: (params) => put(`/repairment/${params.id}`, params),
  deps: (params) => get("/mode-depart/by-udi", params),
};

export const dic = {
  all: () => get("/dict/all"),
};

export const region = {
  tree: () => get("/region/tree"),
};

export const getPosition = (params) => (
  getBase("https://ipapi.co/json/", params)
)