import Qr from "@/pages/qr";
import Home from "@/pages/home";
import Register from "@/pages/register";
import Report from "@/pages/report";
import Record from "@/pages/report/record";
import RecordDetails from "@/pages/report/details";
import ReportSuccess from "@/pages/report/success";
import RegisterSuccess from "@/pages/register/success";

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/:uid",
    component: Qr,
  },
  {
    path: "/home/:uid",
    component: Qr,
  },
  {
    path: "/home/:uid/detail",
    component: Home,
  },
  {
    path: "/register/:machineUdi",
    component: Register,
  },
  {
    path: "/register/success/:machineUdi",
    component: RegisterSuccess,
  },
  {
    path: "/report/:machineUdi",
    component: Report,
  },
  {
    path: "/report/success/:machineUdi",
    component: ReportSuccess,
  },
  {
    path: "/report/details/:recordId",
    component: RecordDetails,
  },
  {
    path: "/report/record/:machineUdi",
    component: Record,
  },
];

export default routes;
