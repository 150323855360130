import { useEffect } from 'react'
import { useParams } from 'react-router'

import useRequest from '@/hooks/useRequest'
import { device } from '@/service'

const useScanData = () => {
  const { uid: uidRoute } = useParams()
  const uid = uidRoute || localStorage.getItem('uid')
  const { data } = useRequest(
    uid,
    device.scan,
    {
      enabled: Boolean(uid)
    }
  )

  useEffect(() => {
    localStorage.setItem('uid', uid || '')
  }, [])

  return data
}

export default useScanData