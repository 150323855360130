import { useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { LANGUAGE_KEY } from '@/constants'
import { Language } from '@/store'

const useLanguage = () => {
  const [language, setLanguage] = useRecoilState(Language)

  const handleChangeLanguage = (value) => {
    setLanguage(value)
    localStorage.setItem(LANGUAGE_KEY, value)
  }

  // 默认使用浏览器语言
  // useEffect(() => {
  //   const defaultLanguage = navigator?.language || (navigator as any)?.userLanguage

  //   if (!language) {
  //     setLanguage([defaultLanguage])
  //   }
  // }, [])

  return [language, handleChangeLanguage] as [any, any]
}

export default useLanguage