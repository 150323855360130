import { useState, useMemo } from 'react'
import { Button, Picker } from 'antd-mobile'
import { DownOutline } from 'antd-mobile-icons'

import useLanguage from '@/hooks/useLanguage'
import { languageColumns } from '@/config/config'

import './index.scss'

const LanguagePicker = () => {
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useLanguage()

  const {
    label,
    img
  } = useMemo(() => {
    return (languageColumns[0]?.find(item => item.value === value[0]) || {}) as any
  }, [value?.[0]])

  return (
    <div>
      <Button className='component-language-picker-btn' onClick={() => setVisible(true)}>
        <span className='component-language-picker-btn-inner'>
          <img src={img}/>
          {label}
          <DownOutline className='component-language-picker-btn-icon'/>
        </span>
      </Button>
      <Picker
        columns={languageColumns}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
        value={value}
        onConfirm={(v) => {
          setValue(v)
        }}
      />
    </div>
  )
}

export default LanguagePicker