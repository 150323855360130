import { useMemo } from 'react'

import en from '@/locale/en'
import cn from '@/locale/zh-CN'
import tw from '@/locale/zh-TW'

import useLanguage from './useLanguage'

const useLocale = () => {
  const [language] = useLanguage()

  const config = useMemo(() => {
    const map = {
      en,
      'zh-CN': cn,
      'zh-TW': tw
    }

    return map[language]
  }, [language])

  const getLocaleText = (key) => {
    return config?.[key] || ''
  }

  const getLocaleKey = (key) => {
    const map = {
      en: 'En',
      'zh-CN': '',
      'zh-TW': 'Tr'
    }

    return key + map[language]
  }

  return {
    language,
    getLocaleText,
    getLocaleKey
  }
}

export default useLocale