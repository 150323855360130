import React from 'react'

import Header, { Props as HeaderProps } from '@/components/Header'
import Message from '@/assets/imgs/message.png'

import './index.scss'

interface Props extends HeaderProps {
  className?: string
  containerClassName?: string
  children?: React.ReactNode
  isShowTabBar?: boolean
  isShowMessage?: boolean
}

const Container: React.FC<Props> = (props) => {
  const {
    title = '',
    rightSlot,
    className = '',
    containerClassName = '',
    isBackHidden,
    children,
    isShowMessage = false,
    onBack
  } = props

  return (
    <div className={`component-page-container ${containerClassName}`}>
      <Header
        title={title}
        rightSlot={
          rightSlot || (
            isShowMessage && <img className='component-header-message' src={Message} alt=''/>
          )
        }
        isBackHidden={isBackHidden}
        // isHomeBack={isShowTabBar}
        onBack={onBack}
      />
      <div className={`component-page-content ${className}`}>{children}</div>
    </div>
  )
}

export default Container