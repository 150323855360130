import { atom } from 'recoil'

import { languageKey } from '@/config/config'
import { LANGUAGE_KEY } from '@/constants'

// const [text, setText] = useRecoilState(textState);
// const count = useRecoilValue(charCountState);

export const UserInfo = atom({
  key: 'userInfo',
  default: '',
});

// 默认使用浏览器语言
export const Language = atom({
  key: 'language',
  default: [localStorage.getItem(LANGUAGE_KEY) || navigator?.language || (navigator as any)?.userLanguage || languageKey.simple],
});