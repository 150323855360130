import { useState, useContext, useMemo, useEffect, useRef } from 'react'
import { Input, CheckList } from 'antd-mobile'

import useDic from '@/hooks/useDic'
import { formContext } from '@/components/Form'

import './index.scss'

const SearchInput = (props) => {
  const { onChange, name, ...rest } = props
  const visible = useRef(false)
  const hospitalList = useRef<any[]>([])
  const form = useContext(formContext)
  const [value, setValue] = useState(form.getFieldValue(name))
  const [selectValue, setSelectValue] = useState()
  const hospital = useDic('hospital')

  const getHospitalList = (val) => {
    if (!val) return []
    return hospital?.filter(item => item.value?.includes(val))
  }

  const changeValue = (val) => {
    setValue(val)
    form.setFieldValue(name, val)
  }

  const handleSelectChange = (v) => {
    setSelectValue(v)
    changeValue(v?.[0])
    visible.current = false
    const list = getHospitalList(v?.[0])
    hospitalList.current = list
  }

  const handleChange = (val) => {
    changeValue(val)
    onChange?.(val)
    const list = getHospitalList(val)
    visible.current = list?.length > 0
    hospitalList.current = list
  }

  useEffect(() => {
    setValue(form.getFieldValue(name))
  }, [form.getFieldValue(name)])

  return (
    <div className='component-search-input-container'>
      <Input {...rest} value={value} onChange={handleChange}/>
      {
        visible.current && (
          <div className='component-searach-input-selector'>
            <CheckList value={selectValue} onChange={handleSelectChange}>
              {
                hospitalList?.current?.map(item => (
                  <CheckList.Item key={item.value} value={item.value}>{item.value}</CheckList.Item>
                ))
              }
            </CheckList>
          </div>
        )
      }
      
    </div>
  )
}

export default SearchInput