export const LANGUAGE_KEY = 'language'

/**
 * type 类型
 * fault 故障编码
 * country 国家
 */
export const DIC_COUNTRY = 'country'
export const DIC_FAULT = 'fault'
export const DIC_TYPE = 'type'
export const DIC_DISINFECT_WAY = 'disinfect_way'

export default {}