import { useState, useEffect, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { Input, Radio, ImageUploader, Button } from "antd-mobile";

import Form, { Item } from "@/components/Form";
import Container from "@/components/Container";
import Select from "@/components/Select";
import Upload from "@/components/Upload";
import SearchInput from "@/components/SearchInput";
import RegionSelect from "@/components/RegionSelect";
import FormTel from "@/components/FormTel";
import useRequest from "@/hooks/useRequest";
import useScanData from "@/hooks/useScanData";
import useCountry from "@/hooks/useCountry";
import useDic from "@/hooks/useDic";
import useLocale from "@/hooks/useLocale";
import { DIC_FAULT, DIC_DISINFECT_WAY, DIC_COUNTRY } from "@/constants";
import { device } from "@/service";

const Report = () => {
  const formRef = useRef<any>();
  const { machineUdi } = useParams();
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");
  const faultColumns = useDic(DIC_FAULT);
  const disinfectWayColumns = useDic(DIC_DISINFECT_WAY);
  const columns = useCountry();
  const { language, getLocaleText } = useLocale();
  const scanData = useScanData();
  const { data } = useRequest(recordId, device.recordOne, {
    force: true,
    enabled: Boolean(recordId),
  });
  const deps = useRequest({ udi: machineUdi }, device.deps);
  const [isDepartOther, setIsDepartOther] = useState(false);
  const [isChina, setIsChina] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otherVisible, setOtherVisible] = useState(false);
  const [otherWayVisible, setOtherWayVisible] = useState(false)
  const navigate = useNavigate();
  const isShowDisinfectWay = ![1, 2].includes(scanData?.type);
  const isConsumable = scanData?.type == 2

  const columnsDeps = useMemo(() => {
    return (
      deps?.data
        ?.map((d) => {
          let value = null
          if (language == "en") {
            value = d.depEn
          } else if (language == "zh_TW") {
            value = d.depTr
          } else {
            value = d.dep
          }

          return {
            label: value,
            value
          }
        })
    );
  }, [deps, language]);

  const handleSubmit = (params) => {
    const api = Boolean(recordId) ? device.recordModify : device.report;
    setLoading(true);

    const fetchParams = {
      ...params,
      regCode: params.regCode || "86",
      fault: Number(params.fault) || params.fault,
      machineUdi: machineUdi,
      provinceCode: params.provinceCode,
      cityCode: (params.provinceCode && params.cityCode) ? `${params.provinceCode}.${params.cityCode}` : undefined,
      regionCode: (params.provinceCode && params.cityCode && params.regionCode) ? `${params.provinceCode}.${params.cityCode}.${params.regionCode}` : undefined,
    };

    if (isChina) {
      delete fetchParams.otherRegion
    }

    if (recordId) {
      fetchParams.id = recordId;
    }

    api(fetchParams)
      .send()
      .then((res: any) => {
        if (res) {
          navigate(`/report/success/${machineUdi}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!data || !deps?.data) return;
    const isChina = data?.country === "China";
    const otherTarget = deps?.data?.find(item => item.dep == "其他")
    const otherValues = Object.values(otherTarget)
    const isDepartOther = otherValues?.includes(data.depart)
    const targetDepart = deps?.data?.find(item => {
      let flag = false
      Object.keys(item)?.forEach(key => {
        if (item[key] == data.depart) {
          flag = true
        }
      })
      return flag
    })

    let depart = null
    if (language == "en") {
      depart = targetDepart?.depEn
    } else if (language == "zh_TW") {
      depart = targetDepart?.depTr
    } else {
      depart = targetDepart?.dep
    }

    const newData = { ...data };

    if (isChina) {
      newData.otherRegion = [];
      newData.otherRegion[0] = data?.provinceCode;
      newData.otherRegion[1] = data?.cityCode;
      newData.otherRegion[2] = data?.regionCode;
      newData.depart = depart
    }

    setIsChina(isChina);
    setIsDepartOther(isDepartOther)
    formRef.current?.setFieldsValue(newData);
  }, [data, deps?.data, language]);

  return (
    <Container title={getLocaleText("report_title")}>
      <Form
        layout="horizontal"
        formRef={formRef}
        initialValues={{
          reported: 0,
        }}
        footer={
          <Button
            loading={loading}
            block
            type="submit"
            color="primary"
            size="large"
          >
            {getLocaleText("btn_submit")}
          </Button>
        }
        onFinish={handleSubmit}
      >
        {
          isConsumable ? (
            <>
              <Item
                name="country"
                label={getLocaleText("input_label_country")}
                rules={[
                  { required: true, message: getLocaleText("input_msg_country") },
                ]}
              >
                <Select
                  name="country"
                  placeholder={getLocaleText("input_placeholder_country")}
                  columns={columns}
                  onConfirm={(v, form) => {
                    const isChina = v === "China"
                    setIsChina(isChina);

                    if (!isChina) {
                      form.setFieldValue("otherRegion", "");
                    }
                  }}
                />
              </Item>
              <Item
                name="otherRegion"
                label={getLocaleText("input_label_region")}
                rules={[
                  { required: true, message: getLocaleText("input_msg_region") },
                ]}
              >
                {isChina ? (
                  <RegionSelect
                    name="otherRegion"
                    placeholder={getLocaleText("input_placeholder_region")}
                    onConfirm={(v, form) => {
                      const value = (v[2] || v[1])?.split(".") || [v[0]];
                      form.setFieldValue("provinceCode", value[0] || undefined);
                      form.setFieldValue("cityCode", value[1] || undefined);
                      form.setFieldValue("regionCode", value[2] || undefined);
                    }}
                  />
                ) : (
                  <Input
                    placeholder={getLocaleText("input_placeholder_region_other")}
                  />
                )}
              </Item>
              <Item
                name="hospital"
                label={getLocaleText("input_label_hospital")}
                rules={[
                  { required: true, message: getLocaleText("input_msg_hospital") },
                ]}
              >
                <SearchInput
                  name="hospital"
                  placeholder={getLocaleText("input_placeholder_hospital")}
                />
              </Item>
              <Item
                name="depart"
                label={getLocaleText("input_label_depart")}
                rules={[
                  { required: true, message: getLocaleText("input_msg_depart") },
                ]}
              >
                <Select
                  name="depart"
                  placeholder={getLocaleText("input_placeholder_depart")}
                  columns={columnsDeps}
                  onConfirm={(v) => {
                    const otherTarget = deps?.data?.find(item => item.dep == "其他")
                    const otherValues = Object.values(otherTarget)
                    setIsDepartOther(otherValues?.includes(v))
                  }}
                />
              </Item>
              {
                isDepartOther && (
                  <Item
                    name="departOther"
                    label={getLocaleText("input_label_depart_other")}
                    rules={[
                      { required: true, message: getLocaleText("input_msg_depart_other") },
                    ]}
                  >
                    <Input
                      placeholder={getLocaleText("input_placeholder_depart_other")}
                    />
                  </Item>
                )
              }
              <Item
                name="linker"
                label={getLocaleText("input_label_linker")}
                rules={[
                  { required: true, message: getLocaleText("input_msg_linker") },
                ]}
              >
                <Input placeholder={getLocaleText("input_placeholder_linker")} />
              </Item>
              <Item
                name="email"
                label={getLocaleText("input_label_email")}
                rules={[
                  { required: !isChina, message: getLocaleText("input_msg_email") },
                ]}
              >
                <Input placeholder={getLocaleText("input_placeholder_email")} />
              </Item>
              <FormTel isChina={isChina}/>
            </>
          ) : null
        }
        <Item
          name="fault"
          label={getLocaleText("input_label_fault")}
          rules={[
            { required: true, message: getLocaleText("input_msg_fault") },
          ]}
        >
          <Select
            name="fault"
            placeholder={getLocaleText("input_placeholder_fault")}
            columns={faultColumns}
            onConfirm={(v) => {
              setOtherVisible(v == "999");
            }}
          />
        </Item>
        {otherVisible && (
          <Item
            name="faultOther"
            label={getLocaleText("input_label_fault_other")}
          >
            <Input
              placeholder={getLocaleText("input_placeholder_fault_other")}
            />
          </Item>
        )}
        {isShowDisinfectWay && (
          <Item
            name="disinfectWay"
            label={getLocaleText("input_label_disinfectWay")}
            rules={[
              {
                required: true,
                message: getLocaleText("input_msg_disinfectWay"),
              },
            ]}
          >
            <Select
              name="disinfectWay"
              placeholder={getLocaleText("input_placeholder_disinfectWay")}
              columns={disinfectWayColumns}
              onConfirm={(v) => {
                setOtherWayVisible(v == "999");
              }}
            />
          </Item>
        )}
        {otherWayVisible && (
          <Item
            name="disinfectWayOther"
            label={getLocaleText("input_label_disinfectWay_other")}
          >
            <Input
              placeholder={getLocaleText("input_placeholder_disinfectWay_other")}
            />
          </Item>
        )}
        <Item name="atts" label={getLocaleText("input_label_img")}>
          <Upload />
        </Item>
      </Form>
    </Container>
  );
};

export default Report;
