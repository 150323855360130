import { Button, Input } from "antd-mobile";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";

import Container from "@/components/Container";
import Form, { Item } from "@/components/Form";
import RegionSelect from "@/components/RegionSelect";
import SearchInput from "@/components/SearchInput";
import Select from "@/components/Select";
import FormTel from "@/components/FormTel";
import { DIC_COUNTRY } from "@/constants";
import useDic from "@/hooks/useDic";
import useLocale from "@/hooks/useLocale";
import useRequest from "@/hooks/useRequest";
import useScanData from "@/hooks/useScanData";
import useCountry from "@/hooks/useCountry";
import { device } from "@/service";

const Register = () => {
  const formRef = useRef<any>();
  const { machineUdi } = useParams();
  const navigate = useNavigate();
  const data = useScanData();
  const deps = useRequest({ udi: machineUdi }, device.deps);
  const [loading, setLoading] = useState(false);
  const [isChina, setIsChina] = useState(false);
  const [isDepartOther, setIsDepartOther] = useState(false);
  const columns = useCountry()
  const { language, getLocaleText } = useLocale();

  const columnsDeps = useMemo(() => {
    return (
      deps?.data
        ?.map((d) => {
          let value = null
          if (language == "en") {
            value = d.depEn
          } else if (language == "zh_TW") {
            value = d.depTr
          } else {
            value = d.dep
          }

          return {
            label: value,
            value
          }
        })
    );
  }, [deps, language]);

  const handleSubmit = (params) => {
    setLoading(true);
    const fetchParams = {
      ...params,
      regCode: params.regCode || "86",
      machineUdi: machineUdi,
      provinceCode: params.provinceCode,
      cityCode: (params.provinceCode && params.cityCode) ? `${params.provinceCode}.${params.cityCode}` : undefined,
      regionCode: (params.provinceCode && params.cityCode && params.regionCode) ? `${params.provinceCode}.${params.cityCode}.${params.regionCode}` : undefined,
    };

    if (isChina) {
      delete fetchParams.otherRegion
    }

    if (data?.id) {
      fetchParams.id = data?.id;
    }
    device
      .register(fetchParams)
      .send()
      .then((res: any) => {
        if (res) {
          navigate(`/register/success/${machineUdi}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!data || !deps?.data) return;
    const isChina = data?.country === "China";
    const otherTarget = deps?.data?.find(item => item.dep == "其他")
    const otherValues = Object.values(otherTarget)
    const isDepartOther = otherValues?.includes(data.depart)
    const targetDepart = deps?.data?.find(item => {
      let flag = false
      Object.keys(item)?.forEach(key => {
        if (item[key] == data.depart) {
          flag = true
        }
      })
      return flag
    })

    let depart = null
    if (language == "en") {
      depart = targetDepart?.depEn
    } else if (language == "zh_TW") {
      depart = targetDepart?.depTr
    } else {
      depart = targetDepart?.dep
    }

    const newData = { ...data };

    if (isChina) {
      newData.otherRegion = [];
      newData.otherRegion[0] = data?.provinceCode;
      newData.otherRegion[1] = data?.cityCode;
      newData.otherRegion[2] = data?.regionCode;
      newData.depart = depart
    }

    setIsChina(isChina);
    setIsDepartOther(isDepartOther)
    formRef.current?.setFieldsValue(newData);
  }, [data, deps?.data, language]);

  return (
    <Container title={getLocaleText("register_title")}>
      <Form
        formRef={formRef}
        layout="horizontal"
        footer={
          <Button
            loading={loading}
            block
            type="submit"
            color="primary"
            size="large"
          >
            {getLocaleText("btn_submit")}
          </Button>
        }
        onFinish={handleSubmit}
      >
        <Item
          name="country"
          label={getLocaleText("input_label_country")}
          rules={[
            { required: true, message: getLocaleText("input_msg_country") },
          ]}
        >
          <Select
            name="country"
            disabled={Boolean(data?.country)}
            placeholder={getLocaleText("input_placeholder_country")}
            columns={columns}
            onConfirm={(v, form) => {
              const isChina = v === "China"
              setIsChina(isChina);

              if (!isChina) {
                form.setFieldValue("otherRegion", "");
              }
            }}
          />
        </Item>
        <Item
          name="otherRegion"
          label={getLocaleText("input_label_region")}
          rules={[
            { required: true, message: getLocaleText("input_msg_region") },
          ]}
        >
          {isChina ? (
            <RegionSelect
              name="otherRegion"
              disabled={data?.provinceCode || data?.cityCode || data?.regionCode}
              placeholder={getLocaleText("input_placeholder_region")}
              onConfirm={(v, form) => {
                const value = (v[2] || v[1])?.split(".") || [v[0]];
                form.setFieldValue("provinceCode", value[0] || undefined);
                form.setFieldValue("cityCode", value[1] || undefined);
                form.setFieldValue("regionCode", value[2] || undefined);
              }}
            />
          ) : (
            <Input
              disabled={data?.otherRegion}
              placeholder={getLocaleText("input_placeholder_region_other")}
            />
          )}
        </Item>
        <Item
          name="hospital"
          label={getLocaleText("input_label_hospital")}
          rules={[
            { required: true, message: getLocaleText("input_msg_hospital") },
          ]}
        >
          <SearchInput
            name="hospital"
            disabled={data?.hospital}
            placeholder={getLocaleText("input_placeholder_hospital")}
          />
        </Item>
        <Item
          name="depart"
          label={getLocaleText("input_label_depart")}
          rules={[
            { required: true, message: getLocaleText("input_msg_depart") },
          ]}
        >
          <Select
            name="depart"
            placeholder={getLocaleText("input_placeholder_depart")}
            columns={columnsDeps}
            onConfirm={(v) => {
              const otherTarget = deps?.data?.find(item => item.dep == "其他")
              const otherValues = Object.values(otherTarget)
              setIsDepartOther(otherValues?.includes(v))
            }}
          />
        </Item>
        {
          isDepartOther && (
            <Item
              name="departOther"
              label={getLocaleText("input_label_depart_other")}
              rules={[
                { required: true, message: getLocaleText("input_msg_depart_other") },
              ]}
            >
              <Input
                placeholder={getLocaleText("input_placeholder_depart_other")}
              />
            </Item>
          )
        }
        <Item
          name="linker"
          label={getLocaleText("input_label_linker")}
          rules={[
            { required: true, message: getLocaleText("input_msg_linker") },
          ]}
        >
          <Input placeholder={getLocaleText("input_placeholder_linker")} />
        </Item>
        <FormTel isChina={isChina}/>
        <Item
          name="email"
          label={getLocaleText("input_label_email")}
          rules={[
            { required: !isChina, message: getLocaleText("input_msg_email") },
          ]}
        >
          <Input placeholder={getLocaleText("input_placeholder_email")} />
        </Item>
        <Item name="socialAct" label={getLocaleText("input_label_account")}>
          <Input placeholder={getLocaleText("input_placeholder_account")} />
        </Item>
      </Form>
    </Container>
  );
};

export default Register;
