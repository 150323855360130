import { Button } from "antd-mobile";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import Container from "@/components/Container";
import { DIC_FAULT, DIC_DISINFECT_WAY } from "@/constants";
import useDic from "@/hooks/useDic";
import useLocale from "@/hooks/useLocale";
import useRequest from "@/hooks/useRequest";
import useScanData from "@/hooks/useScanData";
import { device } from "@/service";

import { Image } from "antd";
import { getStatus } from "../record";
import styles from "./index.module.scss";

const Details = () => {
  const params = useParams();
  const { getLocaleText } = useLocale();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const scanData = useScanData();
  const machineUdi = searchParams.get("machineUdi");
  const { data } = useRequest(params.recordId, device.recordOne, {
    force: true,
  });
  const disinfectWayColumns = useDic(DIC_DISINFECT_WAY);
  const faultColumns = useDic(DIC_FAULT);
  const status = getStatus(data?.state, getLocaleText);
  const fault = faultColumns?.find(
    (faultItem) => faultItem.value == data?.fault
  );
  const disinfectWayText = disinfectWayColumns?.find(item => item.value == data?.disinfectWay)?.label
  const isShowBtn = [0, 1].includes(data?.state);
  const isShowDisinfectWay = ![1, 2].includes(scanData?.type);

  return (
    <Container
      title={getLocaleText("report_record_details_title")}
      className={styles.container}
    >
      <div className={styles.card}>
        <div className={styles.row}>
          <span>{getLocaleText("report_record_date")}：</span>
          <span>{data?.modifyTime}</span>
        </div>
        <div className={styles.row}>
          <span>{getLocaleText("report_record_state")}：</span>
          <span style={{ color: status?.color }}>{status?.text}</span>
        </div>
        <div className={styles.row}>
          <span>{getLocaleText("report_record_code")}：</span>
          <span>{data?.code}</span>
        </div>
        {data?.beginTime && (
          <div className={styles.row}>
            <span>{getLocaleText("report_record_begin_time")}：</span>
            <span>{data?.beginTime}</span>
          </div>
        )}
        {data?.endTime && (
          <div className={styles.row}>
            <span>{getLocaleText("report_record_end_time")}：</span>
            <span>{data?.endTime}</span>
          </div>
        )}
      </div>
      <div className={styles.card}>
        {/* <div className={styles.row2}>
          <span>{getLocaleText('home_register_name_label')}：</span>
          <span>{data?.machineName}</span>
        </div> */}
        <div className={styles.row2}>
          <span>{getLocaleText("report_record_fault")}：</span>
          <span>{data?.fault == "999" ? data?.faultOther : fault?.label}</span>
        </div>
        {isShowDisinfectWay && (
          <div className={styles.row2}>
            <span>{getLocaleText("input_label_disinfectWay")}：</span>
            <span>{data?.disinfectWay == "999" ? data?.disinfectWayOther : disinfectWayText}</span>
          </div>
        )}
        <div className={styles.row2}>
          <span>{getLocaleText("report_record_fault_file")}：</span>
          <div>
            {data?.atts?.map((url) => (
              <Image
                style={{ margin: "3px", display: "inline-block" }}
                src={url}
                key={url}
                width={100}
                height={100}
              />
            ))}
          </div>
        </div>
      </div>
      {isShowBtn && (
        <Button
          block
          color="primary"
          className={styles.btn}
          onClick={() =>
            navigate(`/report/${machineUdi}?recordId=${params.recordId}`)
          }
        >
          {getLocaleText("btn_modify_record")}
        </Button>
      )}
    </Container>
  );
};

export default Details;
