import useRequest from '@/hooks/useRequest'
import { region } from '@/service'

const useRegion = () => {
  const { data, loading } = useRequest({}, region.tree)

  return {
    data,
    loading
  } as any
}

export default useRegion