import { Upload } from "antd";
import { Popup } from "antd-mobile";
import { VideoOutline } from "antd-mobile-icons";
import { useEffect, useState } from "react";

import "./index.scss";

const UploadComponent = (props) => {
  const { value, onChange, disabled } = props;
  const [previewUrl, setPreviewUrl] = useState("");
  const [files, setFiles] = useState<any>([]);
  useEffect(() => {
    setFiles(value?.map((url) => ({ uid: url, url, status: "done" } ?? [])));
  }, []);

  // [
  //   // {
  //   //   uid: '-1',
  //   //   name: 'image.png',
  //   //   status: 'done',
  //   //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   // },
  // ]

  const handlePreview = (res) => {
    const url = res.url ?? res?.response?.body?.url;
    setPreviewUrl(url);
  };

  const handleChange = (res) => {
    const { file, fileList } = res;
    setFiles(fileList);
    if (file.status === "done") {
      let url = file.response.body.url;
      onChange(value ? [...value, url] : [url]);
    }
  };

  return (
    <>
      <Upload
        action="/api/oss/upload"
        listType="picture-card"
        maxCount={5}
        fileList={files}
        data={{
          bucketName: "meds",
        }}
        disabled={disabled}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {(files?.length > 4 ? true : disabled) ? (
          ""
        ) : (
          <VideoOutline fontSize={30} />
        )}
      </Upload>
      <Popup
        visible={Boolean(previewUrl)}
        showCloseButton
        onMaskClick={() => {
          setPreviewUrl("");
        }}
        onClose={() => {
          setPreviewUrl("");
        }}
        bodyStyle={{ width: "100vw", height: "100vh" }}
      >
        <div className="component-upload-preview">
          <img className="component-upload-preview-img" src={previewUrl} />
        </div>
      </Popup>
    </>
  );
};

export default UploadComponent;
