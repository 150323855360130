import useRequest from "@/hooks/useRequest";
import { dic } from "@/service";
import useLanguage from "./useLanguage";

/**
 * type 类型
 * fault 故障编码
 * country 国家
 */
const useDic = (grp) => {
  const { data } = useRequest({}, dic.all);

  const [language] = useLanguage();
  return (data as any)
    ?.filter((item) => item.grp === grp)
    .map((d) => ({
      value: d.value,
      label:
        language == "en"
          ? d.labelEn
          : language == "zh-TW"
          ? d.labelTr
          : d.label,
    }));
};

export default useDic;
